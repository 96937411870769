import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
    selector: 'app-save-button',
    templateUrl: './save-button.component.html',
    styleUrl: './save-button.component.scss'
})
export class SaveButtonComponent {

    @Input() public invalid = true;
    @Input() public addMode = false;

    @Output() public save = new EventEmitter<void>();
    @Output() public closeForm = new EventEmitter<void>();

    public saveEmit(): void {
        this.save.emit();
    }

    public closeFormEmit(): void {
        this.closeForm.emit();
    }
}
