<div class="d-flex justify-content-end pt-4 pb-3">
    <button class="full-width mx-2" mat-raised-button (click)="closeFormEmit()"
            matTooltip="Close form" matTooltipPosition="below">
        <i class="fa-solid fa-rotate-left"></i> Close
    </button>
    <button class="full-width mx-2" mat-raised-button color="warn" (click)="saveEmit()"
            matTooltip="Save" matTooltipPosition="below" [disabled]="invalid">
        <ng-container *ngIf="!addMode else add">
            Save <i class="fa-solid fa-floppy-disk"></i>
        </ng-container>
        <ng-template #add>
            Add <i class="fa-solid fa-plus"></i>
        </ng-template>
    </button>
</div>
