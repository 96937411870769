import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CompanyLinkComponent} from './components/company-link/company-link.component';
import { SaveButtonComponent } from './components/save-button/save-button.component';
import {MatButton} from "@angular/material/button";
import {MatTooltip} from "@angular/material/tooltip";


@NgModule({
    declarations: [
        CompanyLinkComponent,
        SaveButtonComponent
    ],
    imports: [
        CommonModule,
        MatButton,
        MatTooltip
    ],
    exports: [
        CompanyLinkComponent,
        SaveButtonComponent
    ]
})
export class SharedModule {
}
